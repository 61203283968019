import { privacyPt } from "./Privacy Policy/privacy";

const es = {
  translation: {
    ...privacyPt,
    en: "Inglés",
    es: "Español",
    pt: "Portugués",
    ru: "Ruso",
    ar: "Arábica",
    crypto: "Criptomonedas",
    checkout: {
      cash_bank: {
        service: "Nombre del servicio",
        pay_code: "Codigo de pago",
        pay_amount: "Importe a pagar",
        choose_place: "Haga clic en el pago de su elección y siga las instrucciones abajo",
        choose_method: "Sigue las instrucciones listadas abajo y realiza tu pago",
        methods: {
          wallet: "Billetera Digital",
          bank: "Ventanilla",
          agent: "Agentes",
          "bank-mobile": "Banca Móvil",
          "bank-web": "Banca Web",
        },
        paycash: {
          how_to: {
            common: {
              agent: {
                1: "Dirígete a tu punto de pago de efectivo más cercano y menciónale al cajero que vas hacer un pago PAYCASH",
                2: "Indica en caja la referencia (<strong>{{key}}</strong>) y monto del pago",
                3: "Recibe el comprobante de pago",
                4: "Puede hacer clic <0>aquí</0> para buscar el punto de pago más cercano.",
              },
            },
            PER: {
              "Western Union": {
                agent: {
                  1: "Solicita al cajero realizar un pago PayCash en alguno de estos agentes autorizados: Caja Trujillo, Caja Los Andes (Crac – Lasa), Financiera QAPAQ, Edpyme Alternativa, Caja Paita, Caja Municipal Del Santa y Puntos Físico WU",
                  2: "Indica en caja la referencia (<strong>{{key}}</strong>) de <strong>16 dígitos</strong> y monto del pago",
                },
                "bank-web": {
                  1: "Selecciona la opción “operaciones”",
                  2: "Elige la opción “pagos”",
                  3: "Selecciona la opción “Instituciones y Empresas”",
                  4: "Buscas por el nombre PayCash",
                  5: "Se ingresa los <strong>16 dígitos</strong> de la referencia (<strong>{{key}}</strong>) y selecciona buscar",
                  6: "Se confirma el pago",
                  7: "Se autoriza la operación vía token",
                  8: "La banca confirma el estatus de la operación",
                },
              },
              "Banco BCP": {
                agent: {
                  1: "Solicita al cajero realizar un pago PayCash o código de empresa <strong>24162</strong>",
                  2: "Comparte <strong>los últimos 13 dígitos</strong> (<strong>{{key}}</strong>) de la referencia",
                  3: "Indica en caja la referencia y monto del pago",
                  4: "*Solo operaciones monto fijo",
                },
                "bank-web": {
                  1: "Entra a la sección de pagos",
                  2: "Busca por el nombre (PayCash)",
                  3: "Ingresa <strong>los últimos 13 dígitos</strong> (<strong>{{key}}</strong>) de la referencia",
                  4: "Confirma el pago",
                  5: "Ingresar el token correspondiente",
                  6: "Confirma el estatus de la operación",
                  7: "*Solo operaciones monto fijo",
                },
              },
              Yape: {
                "bank-mobile": {
                  1: "Ingresa a la opción pago de servicios y busca por nombre a PayCash",
                  2: "Ingresa <strong>los últimos {{digit}} dígitos</strong> (<strong>{{key}}</strong>) del código de pago para confirmar el mismo",
                  3: "Espera la confirmación de la operación",
                },
              },
              BBVA: {
                agent: {
                  1: "Dirígete a tu Agente BBVA más cercano y menciónale al cajero que vas hacer un pago PAYCASH con el código <strong>{{key}}</strong>",
                },
                "bank-web": {
                  1: "Ingresar a la sección de pagos de servicios",
                  2: "Buscar PayCash",
                  3: "Se ingresar 16 dígitos de la referencia (<strong>{{key}}</strong>)",
                  4: "Se confirma el pago",
                  5: "Ingresa token del cliente",
                  6: "La banca confirma el estatus de la operación",
                },
              },
              Interbank: {
                agent: {
                  1: "Solicita al cajero realizar un pago PayCash o código de empresa <strong>0319901</strong>",
                  2: "Indica en caja la referencia (<strong>{{key}}</strong>) de 16 dígitos y monto del pago",
                  3: "Recibe comprobante de pago",
                  4: "*Solo referencias de monto fijo",
                },
                "bank-web": {
                  1: "Ingresar – Paga o Recarga",
                  2: "Seleccionar – Pago de servicios",
                  3: "Buscar PayCash",
                  4: "Ingresar referencia (<strong>{{key}}</strong>) de 16 dígitos y monto de pago",
                  5: "La banca confirma el estatus de la operación",
                  6: "*Solo referencias de monto fijo",
                },
                "bank-mobile": {
                  1: "Ingresar Operaciones/Servicios",
                  2: "Buscar Paycash",
                  3: "Ingresar referencia (<strong>{{key}}</strong>) de 16 dígitos y monto",
                  4: "La banca confirma el estatus de la operación",
                  5: "*Solo referencias de monto fijo",
                },
              },
              Izipay: {
                wallet: {
                  1: "Ingresa a la opción pago de servicios y busca Servicio PayCash",
                  2: "Ingresa el código de pago <strong>{{key}}</strong> y el monto",
                  3: "La aplicación confirma el estus de la operación",
                },
              },
              "Banco Pichincha": {
                "bank-web": {
                  1: 'Ingrese en "Operaciones", y dentro de la opción "Pagos" busque "PayCash" dentro de la sección "Instituciones y Empresas"',
                  2: "Ingrese el código de pago <strong>{{key}}</strong> y selecciona buscar",
                  3: "Confirme el monto y finalice la operación al autorizar via Token",
                  4: "La banca confirma el estatus de la transacción",
                },
              },
              "Banco del Comercio": {
                agent: {
                  1: "Dirígete a tu Agente Bancario de Banco del Comercio más cercano y menciónale al cajero que vas hacer un pago PAYCASH con el código <strong>{{key}}</strong>",
                },
                "bank-web": {
                  1: "Ingresa a Pago de servicios, selecciona Sociedades y busca Empresa PayCash y servicio PayCash",
                  2: "Ingrese el código de pago <strong>{{key}}</strong> y monto respectivo",
                  3: "La aplicación confirma el estatus de la operación",
                },
                "bank-mobile": {
                  1: "Ingresa a Pago de servicios, selecciona Sociedades y busca Empresa PayCash y servicio PayCash",
                  2: "Ingrese el código de pago <strong>{{key}}</strong> y monto respectivo",
                  3: "La aplicación confirma el estatus de la operación",
                },
              },
              Kasnet: {
                agent: {
                  1: "Solicita al cajero realizar un pago PayCash al convenio <strong>220114</strong>",
                  2: "Indica en caja la referencia (<strong>{{key}}</strong>) de <strong>16 dígitos</strong> y monto del pago",
                },
              },
              Megapunto: {
                agent: {
                  1: "Dirígete al Punto de Pago Megapunto más cercano y menciónale al cajero que vas hacer un pago PAYCASH mediante la App Megapunto - Servicios - Paycash",
                  2: "Brinda el código <strong>{{key}}</strong> y el monto para terminar el pago",
                },
              },
              Bancom: {
                "bank-web": {
                  1: "Ingresa - Pago de servicios",
                  2: "Seleccionar Sociedades",
                  3: "Buscar / Empresa PayCash y Servicio PayCash",
                  4: "Ingresa referencia (<strong>{{key}}</strong>) de 16 dígitos y monto",
                  5: "La aplicación confirma el estatus de la operación",
                },
                "bank-mobile": {
                  1: "Ingresa - Pago de servicios”",
                  2: "Seleccionar Sociedades",
                  3: "Buscar / Empresa PayCash y Servicio PayCash",
                  4: "Ingresa referencia (<strong>{{key}}</strong>) de 16 dígitos y monto",
                  5: "La aplicación confirma el estatus de la operación",
                },
              },
              "Red Digital": {
                agent: {
                  1: "Solicita al cajero realizar un pago PayCash a través de la Web o App RedDigital (MovilWay) – Pasarelas de Pago – Paycash",
                  2: "Indica en caja la referencia (<strong>{{key}}</strong>) de 16 dígitos y monto del pago",
                  3: "Adicional puedes indicar tu # celular para envió de SMS de confirmación del pago",
                  4: "El punto cobrará 1 Sol adicional por el uso del servicio (pago al frente)",
                  5: "Recibe comprobante de pago",
                },
              },
              Niubiz: {
                agent: {
                  1: "Solicita al cajero de una sucursal de Niubiz que acepte pagos de servicios, realizar un pago PayCash en la opción Recargas y Servicios",
                  2: "Indica en caja la referencia (<strong>{{key}}</strong>) de 16 dígitos y monto del pago",
                },
              },
              "Caja Arequipa": {
                bank: {
                  1: "Ingresa tu tarjeta Caja Arequipa y Clave",
                  2: "Selecciona “Otras opciones” -> “Pagos” -> “Servicios” -> “PayCash”",
                  3: "Ingresa el # de Referencia PayCash (<strong>{{key}}</strong>) y confirma monto",
                  4: "Recibe comprobante de pago",
                  5: "*Solo referencias de monto fijo",
                },
                online: {
                  1: "Ingresa a tu Home Banking o App Móvil Caja Arequipa",
                  2: "Ingresa al menú Inicio en la opción de “Pagos”",
                  3: "Selecciona “Pago de Servicios e Instituciones” -> “Otros” -> “PayCash”",
                  4: "Ingresa el # de Referencia PayCash (<strong>{{key}}</strong>) y confirmar monto",
                  5: "Te solicitará clave digital y token",
                  6: "Recibe comprobante de pago digital",
                  7: "*Solo referencias de monto fijo",
                },
              },
              Banbif: {
                agent: {
                  1: "Solicita al cajero de un punto de pago FULL CARGA realizar un pago PayCash",
                  2: "Indica en caja los últimos 14 dígitos (<strong>{{key}}</strong>) de la Referencia PayCash y confirma el monto a pagar",
                  3: "Recibe comprobante de pago",
                },
                "bank-web": {
                  1: "Ingresa a tu Banca Digital Banbif https://www.banbif.com.pe/Personas",
                },
                "bank-mobile": {
                  1: "Ingresa a tu Aplicativo Banbif Móvil",
                },
                online: {
                  2: "Ingresa al menú “Pagar” en la opción “Servicios”",
                  3: "Busca el servicio “PayCash”",
                  4: "Ingresa los últimos 14 dígitos (<strong>{{key}}</strong>) de la Referencia PayCash y confirmar monto",
                  5: "Selecciona la factura a pagar y selecciona el medio de pago",
                  6: "Confirma y aprueba con tu clave token",
                  7: "Recibe comprobante de pago digital",
                  8: "*Solo referencias de monto fijo",
                },
              },
            },
            ECU: {
              "Jardin Azuayo": {
                "bank-web": {
                  1: "Selecciona Servicios -> Recaudaciones -> Pago de servicios básicos -> impuestos y catálogo",
                  2: "Selecciona otras recaudaciones -> PayCas",
                  3: "Ingresa referencia y monto del pago",
                  4: "Confirma el pago",
                },
              },
              "Western Union": {
                agent: {
                  1: "*Módulos Western Union dentro de alguna de estas sucursales: MI COMISARIATO, TIA, HIPERMARKET",
                },
              },
              "Banco Internacional": {
                "bank-web": {
                  1: "Ingresa a la Banca; https://www.bancointernacional.com.ec/",
                  2: "Selecciona opción Banca Online Personas",
                  3: "Ingresa con tu usuario y contraseña",
                  4: "Selecciona Resumen -> Posición Consolidada -> Pagar",
                  5: "Selecciona en grupo de servicios “casas comerciales” -> en empresa “Recaudación Pay Cash” -> clic en convenio “Pay Cash”",
                  6: "Ingresa la referencia y dar click en consultar",
                  7: "Escoge el tipo de pago, cuenta donde se tomará el dinero para el pago y dar click en continuar",
                  8: "Validar los datos y dar click en confirmar -> te enviará # de confirmación al celular registrado",
                  9: "Obtén tu comprobante de pago",
                },
              },
              "Mi Negocio Efectivo": {
                agent: {
                  1: "Solicita al cajero realizar un pago PayCash mediante Pago de Servicio -> Pagos en Línea",
                },
              },
            },
            CRI: {
              "Banco Nacional": {
                agent: {
                  1: "Solicita al cajero de tu Agente Unipunto mas cercano realizar un pago PayCash",
                  2: "El cajero buscará el servicio por nombre PayCash en la plataforma de forma directa o puede buscarlo por la ruta: (Pagos -> cuotas y créditos -> Cuotas-Ahorro-Crédito -> PayCash)",
                  3: "Indicarle en la caja la referencia (<strong>{{key}}</strong>) y monto del pago",
                },
                "bank-web": {
                  1: "Se ingresa a la banca digital y registra su usuario y contraseña",
                  2: "En la sección de “Acceso” en la opción de búsqueda ingresa el nombre del servicio: PayCash",
                  3: "Se ingresa la referencia (<strong>{{key}}</strong>) de 16 dígitos",
                  4: "Se confirma pago y selecciona la forma de pago (debito o crédito)",
                  5: "La banca confirma el estatus de la operación",
                },
              },
              Payser: {
                agent: {
                  1: "Solicita al cajero de tu punto Payser (antes Teledolar) mas cercano realizar un pago PayCash en la opción “Otros Pagos de Servicios”",
                  2: "Indica en la caja la referencia (<strong>{{key}}</strong>) y monto del pago",
                },
              },
              "Tiendas Ekono": {
                agent: {
                  1: "Solicita al cajero realizar un pago PayCash",
                  2: "El cajero buscará el servicio por nombre PayCash en la plataforma de forma directa o puede buscarlo por la ruta: (Pagos -> cuotas y créditos -> Cuotas-Ahorro-Crédito -> PayCash)",
                  3: "Indica en la caja la referencia (<strong>{{key}}</strong>) y monto del pago",
                },
              },
              "Banco Popular": {
                agent: {
                  1: "Solicita al cajero realizar un pago PayCash",
                  2: "El cajero buscará el servicio por nombre PayCash en la plataforma de forma directa o puede buscarlo por la ruta: (Pagos -> cuotas y créditos -> Cuotas-Ahorro-Crédito -> PayCash)",
                  3: "Indica en la caja la referencia (<strong>{{key}}</strong>) y monto del pago",
                },
                "bank-web": {
                  1: "Se ingresa a la banca digital y registra su usuario y contraseña",
                  2: "En la sección de “Pago de Servicios” en la opción de búsqueda ingresa el nombre del servicio: PayCash",
                  3: "Se ingresa la referencia (<strong>{{key}}</strong>) de 16 dígitos",
                  4: "Se confirma pago y selecciona la forma de pago (solo debito)",
                  5: "La banca confirma el estatus de la operación",
                },
              },
              "Banco Promerica": {
                "bank-web": {
                  1: "Ingresa a la banca digital y registra su usuario y contraseña",
                  2: "En la sección de “Pago de Servicios” en la opción de búsqueda ingresa el nombre del servicio: PayCash",
                  3: "Se ingresa la referencia (<strong>{{key}}</strong>) de 16 dígitos",
                  4: "Se confirma pago y selecciona la forma de pago (solo debito)",
                  5: "La banca confirma el estatus de la operación",
                },
              },
            },
            MEX: {
              BWS: {
                1: "Solicita al cajero realizar un pago PayCash al servicio <strong>198</strong>",
              },
              Santander: {
                agent: {
                  1: "Solicita en ventanilla realizar un pago PayCash al Convenio <strong>7292</strong>",
                },
                "bank-web": {
                  1: "Ingresar a la banca electrónica",
                  2: "Cuentas - operaciones más usadas",
                  3: "Servicios - PayCash",
                  4: "Capturar REFERENCIA e Importe",
                },
              },
              BBVA: {
                bank: {
                  1: "Dirigete a tu Practicaja mas cercano",
                  2: "Seleccionas pago de servicio",
                  3: "Convenio 1420712",
                  4: "Ingresar Referencia y Monto",
                  5: "*Tiempo en que se refleja la operación: 30 minutos a 1 hora",
                },
                "bank-web": {
                  1: "Saldo",
                  2: "Pagar Servicio",
                  3: "Nuevo Servicio",
                  4: "Ingresar CIE1420712 y seleccionar Red Efectiva",
                  5: "Capturar REFERENCIA e Importe",
                  6: "*Tiempo en que se refleja la operación: 30 minutos a 1 hora",
                },
              },
              Banorte: {
                bank: {
                  1: "Solicita en ventanilla realizar un pago PayCash al Convenio 3724",
                  2: "Proporciona referencia y el monto a pagar",
                  3: "*Tiempo en que se refleja la operación: 24 a 72 horas hábiles",
                },
                "bank-web": {
                  1: "Ingresar a Pagos de servicios",
                  2: "Buscar Convenio 3724 o por nombre PAYCASH - TELECOMM",
                  3: "Seleccionar cuenta donde se obtendrá el saldo",
                  4: "Capturar REFERENCIA e Importe",
                  5: "*Tiempo en que se refleja la operación: 24 a 72 horas hábiles",
                },
              },
              "Banco AFIRME": {
                "bank-web": {
                  1: "Ingresar a Pagos de servicios",
                  2: "Buscar por nombre PAYCASH",
                  3: "Seleccionar cuenta donde se obtendrá el saldo",
                  4: "Capturar REFERENCIA e Importe",
                },
              },
            },
            GTM: {
              AKISI: {
                agent: {
                  1: "Solicita al cajero realizar un pago PayCash a través de AKISI (Pronet) – Grupo de Servicios Varios",
                  2: "Indica en caja la referencia y monto del pago",
                  3: "*Solo Monto fijo",
                },
                bank: {
                  1: "Acude al Cajero Inteligente Akisi",
                  2: "Ingresa en opción Servicios Varios - Paycash - Pago",
                  3: "Ingresa # de referencia y dar clic en Confirmar",
                  4: "Confirma el monto a pagar y dar clic en OK",
                  5: "Validar los datos del pago y dar clic en Confirmar",
                  6: "Se habilitará el ingreso del dinero a través del receptor de billetes – Ingresa el monto indicado",
                  7: "Recibe tu comprobante del pago efectuado",
                  8: "Recibe el vuelto en caso de haber depositado una cantidad mayor a la solicitada",
                  9: "*Solo Monto fijo",
                },
                "bank-mobile": {
                  1: "Utiliza tu usuario y contraseña en app AKISI",
                  2: "Ingresa en opción Servicios - Grupo de Servicios Varios - Paycash",
                  3: "Colocar Referencia y escribir Alias (ejemplo pago PayCash) – dar clic en Guardar",
                  4: "Ingresar nuevamente a la opción Grupo de Servicios Varios y se selecciona el pago Paycash guardado en el paso anterior",
                  5: "En el espacio Datos se ingresa el monto a cancelar y dar clic en Pagar",
                  6: "Confirmar el pago dando clic en Realizar transferencia",
                  7: "*Solo Monto fijo",
                },
              },
              "Super 24": {
                agent: {
                  1: "Solicita al cajero realizar un pago PayCash a través de AKISI (Pronet) – Grupo de Servicios Varios",
                  2: "Indica en caja la referencia y monto del pago",
                  3: "*Solo Monto fijo",
                },
              },
            },
            PAN: {
              "Punto Pago": {
                agent: {
                  1: "Seleccionar en Kiosko el botón PayCash",
                  2: "Ingresar el # de referencia PayCash y Confirmar",
                  3: "Depositar el monto solicitado y seleccionar Pagar",
                  4: "El sistema imprimirá el comprobante de pago",
                  5: "*Considerar que el Kiosko no regresa vuelto(cambio), debe depositar el monto exacto a pagar solamente",
                },
                "bank-mobile": {
                  1: "Ingresar a APP Punto Pago",
                  2: "Buscar el operador PayCash",
                  3: "Ingresar la referencia a pagar y seleccionar Guardar",
                  4: "Seleccionar cuenta a pagar, la cual fue guardada en el punto anterior",
                  5: "Ingresar el monto a depositar y seleccionar Pagar",
                },
              },
            },
          },
        },
      },
      pse: {
        company_info: "Datos empresa",
        business_name: "Razón social",
        trx_detail: "Datos de transacción",
        trx_status: "Estado de la transacción",
        trx_date: "Fecha de creación de la transacción",
        bank: "Banco",
        tracking_code: "Código único de seguimiento (CUS)",
        reference: "Referencia de pago (ticketID)",
        amount: "Valor de pago",
        description: "Descripción del pago",
        status: {
          FALLIDA: "FALLIDA",
          APROBADA: "APROBADA",
          RECHAZADA: "RECHAZADA",
          PENDIENTE: "PENDIENTE",
        },
        alert: {
          pending:
            "Por favor verificar si el débito fue realizado en el banco Si requiere más información sobre su transacción comuníquese a nuestro correo electrónico destinado para la atentión al cliente support.latam@pagsmile.com",
        },
        error: {
          FAIL_EXCEEDEDLIMIT:
            "El monto de la transacción excede los límites establecidos en PSE para la empresa, por favor, comuníquese a nuestro correo electrónico destinado para la atentión al cliente support.latam@pagsmile.com",
          default:
            "No se puede crear la transacción, por favor, intente más tarde o comuníquese a nuestro correo electrónico destinado para la atentión al cliente support.latam@pagsmile.com",
        },
      },
    },
    search: "Buscar",
    select_bank: "Selecciona tu banco",
    fail_change_method: "Algo salió mal. Utilice otro método de pago.",
    service_name: "Nombre del servicio",
    bank_code: "Codigo bancario:",
    no_channel: "No canal, contacte el comercio",
    mon_bankMobile: "Banca Móvil",
    mon_bankWeb: "Banca Web",
    mon_bank: "Ventanilla",
    mon_agent: "Agentes",
    mon_chooseBank: "1. Escoge el banco o agente",
    mon_chooseMethod: "2. Escoge el método de pago",
    faq: "Preguntas Frecuentes",
    pago_method: "Banca móvil, QR (Yape, Plin), Agentes y Bodegas",
    efecty_faq1q: "Qué es Efecty?",
    efecty_faq1a:
      "Efecty es una empresa con sede en Colombia que ofrece una variedad de métodos de pago que incluyen giros postales, pagos, recargas y cobros en todo el país. Los clientes pueden pagar en línea y pagar más tarde en cualquiera de los 8000 puntos de servicio en el país.",
    efecty_faq2q: "Como puedo encontrar um sitio para pagar en efectivo?",
    efecty_faq2a: "Ve a https://mapapap.efecty.com.co/pagos-personas y busca tu dirección",
    efecty_faq3q: "Como saber si mi pedido fue bien sucedido?",
    efecty_faq3a:
      "Las compras se aprueban inmediatamente y recibirá un correo electrónico confirmando su pago.  Si tienes dudas con la compra que estas realizando llámanos en las líneas de atendimento al cliente.",
    khipu_faq1q: "Qué es Khipu?",
    khipu_faq1a:
      "Khipu es una manera de recibir pagos con transferencia que funciona en cualquier dispositivo, de manera responsiva, y cualquier navegador.",
    khipu_faq2q: "Como funciona?",
    khipu_faq2a:
      "Simplemente ingrese su información de Khipu (número de tarjeta y contraseña) y complete la transacción en el sitio web o la aplicación de su banco.",
    khipu_faq3q: "Como saber si mi pedido fue bien sucedido?",
    khipu_faq3a:
      "Las compras se aprueban inmediatamente y recibirá un correo electrónico confirmando su pago.  Si tienes dudas con la compra que estas realizando llámanos en las líneas de atendimento al cliente.",
    help_chat: "Chat de ayuda",
    okay: "Okay!",
    select_notice: "Escoger banco o agente",
    instructions: "Instruciones",
    videos: "Videos",
    pictures: "Fotos",
    text: "Texto",
    online_inst1: "Log on to online banking as usual.",
    online_inst2: "Enter your 6 digit password.",
    online_inst3: "Select Payment and Services and companies category. Then enter in the search “SafetyPay”.",
    online_inst4:
      "Selecting this option will request the transaction number and the amount that was provided by the merchant.",
    online_inst5: "Confirm the transaction by entering your electronic key (token) and you are done!",
    payment_method: "Método de pago",
    online_banking: "Banco en línea",
    cash_payments: "Pago en efectivo",
    bank_agent: "Escoger banco o agente",
    filling_details: "Detalles personales",
    fill_info: "Completa tu información",
    details: "Detalles",
    name: "Nombre",
    email: "Correo electrónico",
    TIN: "TIN (Tax ID)",
    cash: "Dinero",
    phone: "Número de teléfono móvil",
    time_remain: "Tiempo restante",
    trx_id: "ID de la transacción:",
    hide: "Ocultar",
    show: "Mostrar",
    confirm: "Confirmar",
    return: "Return",
    attention: "ATENCIÓN: Por favor, consulta la siguiente información.",
    product_description: "Descripción del producto",
    order_value: "Valor del pedido",
    bank_fee: "Taja del banco",
    discount: "Descuento",
    total: "Total",
    privacy_policy_error:
      "Debe leer y aceptar los Términos de uso y la Política de privacidad para completar su compra.",
    privacy_policy1: "He leído y acepto los Términos de uso y la",
    privacy_policy2: " Política de privacidad",
    privacy_policy3: " de Luxpag.",
    invalid_name: "Nombre inválido",
    invalid_TIN: "ID inválido",
    invalid_email: "Correo electrónico inválido",
    invalid_phone: "Número de teléfono inválido",
    payment_inst: "Instrucciones de pago:",
    payment_inst_top: "Registramos su pedido y estamos esperando el pago para iniciar la entrega. Ya casi terminamos!",
    payment_ticket: "Tíquete de pago",
    payment_code: "Código de pago",
    pay: "Pagar:",
    pay2: "Pagar",
    processing: "Procesando",
    processing_notice: "La transacción está en proceso, solo se queda un rato. ¡Gracias por usar Luxpag!",
    success: "Pago exitoso",
    success_notice:
      "Has pagado el pedido correctamente. Si quieras, regrese a la página del vendedor. ¡Gracias por usar Luxpag!",
    cancelled: "Cancelado",
    cancelled_notice:
      "El límite de tiempo para su pago ha finalizado, vuelva a la página del vendedor para realizar un nuevo pedido.",
    failed: "Falla",
    copied: "Copiado con éxito",
    copy: "Copiar",
    visit_us: "Para saber mas sobre nosotros, visite",
    fail_notice: "Es posible que alguna información este incorrecta, intenta nuevamente o comunícate con nosotros",
    fail_query: "Quieres intentar de nuevo?",
    yes: "Sí, quiero",
    help_me: "Ayúdame",
    order: "Pedido",
    back_shop: "Volver a las compras",
    credit_card: "Tarjeta de crédito",
    credit_debit_card: "Tarjeta de crédito/débito",
    debit_card: "Tarjeta de débito",
    "payment.redirect.notice1": "Usted será redireccionado a la página del comercio en {{key}} segundos.",
    "payment.redirect.notice2": "Haga click ",
    "payment.redirect.notice3": "aquí para",
    "payment.redirect.notice4": " cancelar.",
    oxxo: "Pago en efectivo OXXO",
    wallet: "Billetera Digital",
    cardholder_name: "Nombre del titular de la tarjeta",
    card_no: "Número de tarjeta",
    invalid_card_no: "Numero de tarjeta invalido",
    exp_year: "Año de venc.",
    exp_date: "Fecha de caducidad (MM/YYYY)",
    invalid_exp_date: "Fecha de caducidad invalido",
    invalid_year: "Seleccione un año",
    exp_month: "Mes",
    month: "mes",
    invalid_month: "Selecciona un mes",
    state: "Estado",
    invalid_state: "Selecciona un Estado",
    city: "Ciudad",
    invalid_city: "Seleccionar una ciudad",
    postal_code: "Código postal",
    invalid_postal_code: "Código postal invalido",
    street: "Calle",
    invalid_street: "Calle invalido",
    number: "Número",
    invalid_number: "Número invalido",
    source: "Sitio web o nombre de la APP donde compró el producto",
    invalid_source: "Invalido",
    source_notice: "Si la información ingresada es incorrecta, el pago de su pedido podría fallar",
    todito_notice: "Ingrese su información de Todito Cash a continuación:",
    "checkout_inst1.1": "Estimado usuario, pague ",
    "checkout_inst1.2": " para finalizar su compra.",
    "checkout_inst2.1": "Escanee el código QR para ir a la ",
    "checkout_inst2.1.1": "Haga clic en el botón para ir a la ",
    "checkout_inst2.2": " APP y complete el pago.",
    go_to: "Ir a ",
    checkout_inst3: "1. Ve a la tienda más cercana",
    checkout_inst4: "2. Dígale al cajero que tiene un pago de Luxpag y muestre este número:",
    share: "Cuota",
    save_pdf: "Guardar PDF",
    checkout_inst5: "Abra su aplicación bancaria e ingrese la información a continuación",
    checkout_inst6: "Ingrese este número de referencia: ",
    register_info: "Información para registrar la cuenta:",
    installments: "Pago a plazos",
    invalid_cvv: "CVV invalido",
    invalid_PIN: "PIN invalido",
    invalid_NIP: "NIP invalido",
    paid_major: "La mayor parte pagada",
    paid_minor: "Parte menor pagada",
    refund_verifying: "Verificando reembolso",
    refund_processing: "Procesando reembolso",
    risk_control: "Control de riesgos",
    refunded: "Reembolsado",
    partial_refund: "Parcialmente reembolsado",
    chargeback: "Contracargo",
    chargeback_reversed: "Contracargo invertido",
    dispute: "Disputa",
    dispute_reversed: "Disputa revertida",
    timeout: "Transacción agotada",
    payment_refuse: "Pago rechazado",
    payment_fail: "Pago fallido",
    refund_reverse: "Reembolso revertido",
    refund_refuse: "Reembolso rechazado",
    pay_close: "Cerrado",
    fail_sec: "Para la seguridad de su transacción, el pedido fue cerrado. Por favor, vuelva a enviarlo",
    invalid_cep: "CEP invalido",
    boleto_inst1: "¡Boleto generado!",
    boleto_inst2: "Puedes pagar en bancos, lotéricas, correos, supermercados o en línea",
    boleto_inst3:
      "Ya hemos enviado un correo electrónico con los detalles. Para descargar el archivo haga clic en el botón 'Guardar PDF'",
    deposit_upload_inst1: "¡Encantado de tenerte de vuelta!",
    deposit_upload_inst2:
      "Ya casi finalizamos, completa este breve formulario y cargue el comprobante de pago para terminar :)",
    upload: "Subir",
    back: "Volver",
    wallet_inst1: "Escanear y pagar",
    wallet_inst2: "Puedes escanear y pagar usando:",
    wallet_inst3: "¿Cómo pagar?",
    wallet_inst4: "1. Abre la aplicación de la billetera en su dispositivo",
    wallet_inst5: '2. Ve a la pestaña y ház click en "escanear" ',
    wallet_inst6: "3. Escanea el QR de pago y completa el pago",
    wallet_mob_ins1: "Ház clic y paga",
    wallet_mob_ins2: "Puedes hacer clic y pagar con:",
    wallet_mob_ins3: "1. Ház clic en el botón de abajo para ir a la APLICACIÓN ",
    "wallet_mob_ins3.1": " en tu teléfono",
    wallet_mob_ins4: "2. Procede a completar el pago",
    "SYS-0001": "Su pago fue rechazado. Elija otro método de pago. Recomendamos métodos de pago en efectivo.",
    "SYS-0002": "Para la seguridad de su transacción, el pedido está cerrado, vuelva a enviarlo",
    "SYS-0003": "Elija el valor más alto",
    "SYS-0004": "Proporcione la dirección de correo electrónico válida.",
    "CC-0001": "Pago denegado. El nombre y el CPF no coinciden. Ingrese la información correcta.",
    "CC-0002": "Token de tarjeta no válido",
    "CC-0003":
      "Estamos procesando el pago. Dentro de 2 días hábiles te informaremos por correo electrónico del resultado.",
    "CC-0004":
      "Estamos procesando el pago. Dentro de 2 días hábiles te informaremos por correo electrónico si ha sido aprobado o si necesitamos más información.",
    "CC-0005": "Verifique el número de tarjeta.",
    "CC-0006": "Verifique la fecha de vencimiento",
    "CC-0007": "Verifique los datos.",
    "CC-0008": "Verifique el código de seguridad.",
    "CC-0009": "No pudimos procesar su pago.",
    "CC-0010": "Debe autorizar a la Tarjeta a pagar el monto a Luxpag.",
    "CC-0011": "Tarjeta de llamada para activar su tarjeta. El teléfono está en el reverso de su tarjeta.",
    "CC-0012": "No pudimos procesar su pago.",
    "CC-0013":
      "Ya realizó un pago con esta cantidad. Si necesita pagar nuevamente, use otra tarjeta u otra forma de pago",
    "CC-0014": "Su pago ha sido rechazado. Elija otro método de pago. Recomendamos métodos de pago en efectivo.",
    "CC-0015": "La tarjeta tiene saldo insuficiente.",
    "CC-0016": "La Tarjeta no procesa pagos en cuotas",
    "CC-0017": "Ha alcanzado el límite de intentos permitido. Elija otra tarjeta u otra forma de pago.",
    "CC-0018": "La tarjeta no procesó su pago.",
    bank_deposit: "Depósito Bancário",
    payment_lottery: "Pago en Lotérica",
    checkoutLottery_title:
      "Esta forma de pago no es mediante transferencia bancaria. Pague en la lotería utilizando los datos a continuación:",

    checkoutLottery_contentText1: "Código de seguro médico",
    checkoutLottery_contentText2: "Número CPF / CNPJ",
    checkoutLottery_instContainer1: "Duda a la hora de realizar el pago? Pincha",
    checkoutLottery_instContainer2: "aquí",
    checkoutLottery_instContainer3: "y mira el vídeo explicativo.",
    checkoutLottery_instContainer4: "La aprobación se realiza en el plazo de 1 hora después del pago.",

    depositExpress_inst_title1: "El pago se realiza en dos pasos:",
    depositExpress_inst_title2: "1. Haz una transferencia, depósito o PIX",
    depositExpress_inst_title3: "2. Envia el comprobante de pago",
    depositExpress_bank_name: "Nombre del Banco",
    depositExpress_provider_agency: "Agencia:",
    depositExpress_provider_number: "Cuenta corriente:",
    depositExpress_provider_owner: "Poseedor:",
    depositExpress_provider_owner_document: "CNPJ:",
    depositExpress_provider_chave_pix: "Tecla PIX:",
    depositExpress_buttonContainer: "Haga clic aquí para enviar prueba",
    payment_type_value: "Tipo de pago",
    payment_type_value_invalid: "Tipo de pago no válido",
    agent_value_helpertext: "Correcto: longitud de 4 bits a 6 bits, puede haber '-' en el medio",
    agent_value_label: "Agencia",
    account_valid_helpertext:
      "El formato correcto es de 5 a 12 dígitos. Comienza con un número, debe haber un - en el medio y el último dígito es un número o una x(X).",
    account_valid_label: "Cuenta",
    name_valid_label: "Nombre completo",
    drop_zone_custom_title: "Cargar comprobante de pago",
    file_valid_text: "Sube un archivo",
    depositExpress_uploadSuccess: "Documento cargado exitosamente. Espere a que su pago sea procesado y confirmado.",
    barcode: "código de barras",
    checkoutPix_instContainer:
      "Abra su aplicación de pago y escanea el código QR a continuación para pagar o copie el código Pix a continuación y péguelo en su aplicación de pago para finalizar la compra.",

    checkoutSpei_openpay_h4: "Desde BBVA",
    checkoutSpei_openpay_content1:
      '1. Dentro del menú de "Pagar" seleccione la opción "Servicios" e ingrese el siguiente "Número de convenio CIE"',
    checkoutSpei_openpay_instValue: "Número de convenio CIE:",
    checkoutSpei_openpay_content2: "2. Ingrese los datos de registro para concluir con la operación.",
    checkoutSpei_reference: "Referencia:",
    checkoutSpei_openpay_monto: "Número:",
    checkoutSpei_openpay_from: "Desde cualquier otro banco",
    checkoutSpei_openpay_content3:
      "1. Ingresa a la sección de transferencias y pagos o pagos a otros bancos y proporciona los datos de la transferencia:",
    checkoutSpei_openpay_beneficiary: "Beneficiario:",
    checkoutSpei_destinationbank: "Banco destino:",
    checkoutSpei_description: "Concepto de pago:",
    checkoutSpei_amount: "Importe:",
    timed_out: "¡Se agotó el tiempo de espera de la solicitud de transacción!",
    network_err: "Error de red, por favor, inténtelo de nuevo.",
    system_busy: "Sistema ocupado, por favor inténtelo más tarde.",
    pay_points1: "Puede hacer clic",
    pay_points2: " aquí ",
    pay_points3: "para ver todos los puntos de pago.",
    clap_cash_title: "¿Cómo pagar?",
    pay_cash_ins1:
      "Dirígete a tu punto de pago de efectivo más cercano y menciónale al cajero que vas hacer un pago PAYCASH con el código ",
    clap_cash_ins1: "Dirígete a tu punto Klap EFECTIVO más cercano y solicita pagar con el código ",
    clap_cash_ins2: "Indícale a la persona del comercio que las opciones en la máquina son 2-8-1",
    clap_cash_ins3: "Paga y recibe tu comprobante",
    pay_points_search: " para buscar el punto de pago más cercano.",
    klap_store1: "Use",
    klap_store2: " este link ",
    klap_store3: "para buscar su tienda Klap más cercana",
    address_billing: "Dirección de envio:",
    address_delivery: "Dirección de entrega:",
    address_same: "Igual a la dirección de facturación",
    select_country: "¡Por favor seleccione un país primero!",
    country: "País",
    invalid_country: "Seleccione un país",
    baloto_notice1: "Recibo VIA Baloto generado!",
    sured_notice1: "Recibo Su Red generado!",
    gana_notice1: "Recibo Gana generado!",
    baloto_notice2: "Para realizar tu pago informa los siguientes dato en el punto VIA Baloto de tu preferencia:",
    sured_notice2: "Para realizar tu pago informa los siguientes dato en el punto Su Red de tu preferencia:",
    gana_notice2: "Para realizar tu pago informa los siguientes dato en el punto Gana de tu preferencia:",
    ref_no: "No. de pago: ",
    agree_code: "Convenio: ",
    id: "Cedula de identidad: ",
    amount: "Total a pagar: ",
    due_date: "Fecha de vencimiento",
    barcode_step1:
      "Dirígete a tu punto de pago de efectivo más cercano y menciónale al cajero que vas hacer un pago con el código ",
    bank_list: "Lista de bancos",
    bank_select: "Seleccione un banco",
    agent: "Agentes",
    agent_select: "Seleccionar un agente",
    account_number: "Número de cuenta",
    account_invalid: "Número de cuenta invalido",
    checking_account: "Cuenta corriente",
    saving_account: "Cuenta de ahorros",
    master_account: "Cuenta administradora",
    lp_ins1: "El pago se puede realizar mediante transferencia o depósito bancario.",
    lp_ins2: "Fecha de caducidad: ",
    lp_ins3: "Número de referencia: ",
    lp_ins4: "Información de la cuenta:",
    lp_ins5: "Nombre del banco",
    lp_ins6: "Tipo de cuenta",
    lp_ins7: "Número de cuenta",
    lp_ins8: "Nombre del Beneficiario",
    lp_ins9: "Tipo de Documento",
    lp_ins10: "Identificación del documento",
    lp_ins11: "El número de referencia debe ser exactamente el mismo.",
    refresh: "Recargar",
    refresh_notice:
      "La página se actualizará automáticamente en 30 segundos, de lo contrario, haz clic en el botón de abajo.",
    trsf1: "Abra la aplicación de su institución financiera",
    trsf2: "Vaya a: Servicios > Transacciones > Transfiya",
    trsf3: "Seleccione la cuenta con la que desea pagar",
    trsf4: "Paga a esta cuenta: ",
    trsf5: "Recuerda transferir el monto exacto de ",
    trsf6: ", de lo contrario la transacción fallará",
    spei_key: "Esta clave solo se puede usar una vez",
    spei_clabe: "CLABE interbancária",
    spei_bank: "Banco de destino",
    spei_recipient: "Beneficiário",
    baloto_faq1q: "¿Cómo pagar con VIA Baloto?",
    baloto_faq1a:
      "Menciona los datos al cajero en el punto VIA Baloto de tu preferencia y verifica que la información coincida con el recibo. Este cupón es válido para este pedido. Si realizas dos pagos, se cancelarán.",
    payvalida_notice: "Recibo {{key}} generado!",
    payvalida_notice2: "Para realizar tu pago informa los siguientes dato en el punto {{key}} de tu preferencia:",
    address: "Dirección",
    invalid_address: "Dirección inválida",
    bncr_cash1:
      "Dirígete al punto de pago BN Servicios más cercano y solicita al cajero realizar un pago {{key}} con el No. de referencia brindado arriba",
    bncr_cash2: "Paga y recibe tu comprobante",
    bncr_bank1: "Ingresa al Internet Banking del Banco Nacional con su identificación y contraseña",
    bncr_bank2: "Realiza búsqueda por nombre: Payválida e ingresa el no. de referencia",
    bncr_bank3: "Se confirma la orden, selecciona la forma de pago y espera la confirmación",
    bncr_paycash0:
      "Dirígete al punto de pago BN Servicios más cercano y solicita al cajero realizar un pago PayCash con el código ",
    bncr_paycash1: "Ingresa al Internet Banking del Banco Nacional con su identificación y contraseña",
    bncr_paycash2: "Realiza búsqueda por nombre: Paycash e ingresa la referencia de 16 dígitos",
    bncr_paycash3: "Se confirma la orden, selecciona la forma de pago y espera la confirmación",
    digital_banking: "Banca Digital",
    wallet_inst7: "El número de referencia también se puede utilizar para pagar.",
    20000: "Servicio actualmente no disponible",
    40002: "Transacción falló",
    40013: "El orden no existe",
    40014: "Orden finalizada",
    40015: "La información del pedido es inconsistente",
    40016: "Se superó el valor máximo (impuestos incluidos)",
    40024: "Tiempo limite de la orden",
    20005: "Falló al cargar",
    90001: "El banco está ocupado",
    "70001-10001": "El CPF ingresado no es válido, ingrese otro",
    "70001-10002": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
    "70001-10003": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
    "70001-10004": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
    "70001-20001": "Superado el límite de transacciones",
    "70001-99999": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
    err_100001: "Algo salió mal. Vuelve a intentarlo más tarde",
    err_100002: "Algo salió mal. Utilice otro método de pago.",
    err_100003: "Algo salió mal. Intente cargar de nuevo",
    err_100004: "Subida fallida",
    err_10000: "Éxito",
    err_20000: "Servicio actualmente no disponible",
    err_20001: "Faltan los parámetros obligatorios",
    err_20002: "Método no admitido",
    err_20003: "Tipo de medio no admitido",
    err_40000: "Transacción falló",
    err_40001: "El número de comerciante no es válido",
    err_40002: "El comerciante está bloqueado",
    err_40003: "Número de orden de comerciante duplicado",
    err_40004: "La interfaz de integración no es compatible",
    err_40005: "No admite conexión directa",
    err_40010: "Tipo de suscripción no válido",
    err_40011: "Suscripción inválida",
    err_40012: "El orden no existe",
    err_40013: "Orden finalizada",
    err_40014: "La información del pedido es inconsistente",
    err_40015: "Se superó el valor máximo (impuestos incluidos)",
    err_40016: "La moneda no es compatible",
    err_40017: "No se puede obtener el tipo de cambio",
    err_40020: "La aplicación no es válida",
    err_40021: "La aplicación no existe",
    err_40022: "La clave de la aplicación no es válida",
    err_40023: "El ID de la aplicación es inconsistente",
    err_40101: "Tiempo limite de la orden",
    err_40201: "Falló al cargar",
    err_40301: "El banco está ocupado",
  },
};

export default es;
