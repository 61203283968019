import { privacyEn } from "./Privacy Policy/privacy";

const en = {
  translation: {
    ...privacyEn,
    en: "English",
    es: "Spanish",
    pt: "Portuguese",
    ru: "Russian",
    ar: "Arabic",
    crypto: "Cryptocurrencies",
    expiry_month: "Expiry month",
    expiry_year: "Expiry year",
    checkout: {
      cash_bank: {
        service: "Service",
        pay_code: "Payment code",
        pay_amount: "Amount",
        choose_place: "Click on your payment of choice and follow the instructions below",
        choose_method: "Please follow the instructions listed below and make your payment",
        methods: {
          wallet: "Wallet",
          bank: "Bank",
          agent: "Agent",
          "bank-mobile": "Mobile",
          "bank-web": "PC",
        },
        paycash: {
          how_to: {
            common: {
              agent: {
                1: "Go to your nearest cash payment point and mention to the cashier that you are going to make a PAYCASH payment",
                2: "Indicate the reference (<strong>{{key}}</strong>) and amount of the payment at the cashier",
                3: "Receive the payment receipt",
                4: "You can click <0>here</0> to search nearest payment point.",
              },
            },
            GTM: {
              "Banco Agromercantil": {
                agent: {
                  1: "Dirígete al punto de pago de efectivo mas cercano, mencione al cajero que vas a hacer un pago PayCash al convenio (transacción) <strong>{{code}}</strong> con el código <strong>{{key}}</strong>",
                },
                "bank-web": {
                  1: "Ingresar a la banca electrónica",
                  2: "BAM Virtual Personas - Pagar- Servicios - Nuevo Servicio - PayCash o convenio (transacción) <strong>{{code}}</strong>",
                  3: "Ingresa referencia y monto del pago.",
                },
              },
              "Banco G&T Continental": {
                agent: {
                  1: "Dirígete al punto de pago de efectivo mas cercano, mencione al cajero que vas a hacer un pago PayCash al convenio <strong>{{code}}</strong> con el código <strong>{{key}}</strong>",
                },
                "bank-web": {
                  1: "Ingresar a la banca electrónica",
                  2: "Menú - servicios PayCash o convenio <strong>{{code}}</strong>",
                  3: "Número de cuenta de debito",
                  4: "Ingresa referencia y monto del pago.",
                },
              },
              Pronet: {
                agent: {
                  1: "Dirígete al punto de pago de efectivo mas cercano, mencione al cajero que vas a hacer un pago PayCash a través de AKISI (Pronet) - Grupo de Servicios Varios con el código <strong>{{key}}</strong>",
                },
                "bank-web": {
                  1: "Utiliza tu usuario y contraseña en tu app AKISI",
                  2: "Ingresa en opción Servicios - Grupo de Servicios Varios - PayCash. Coloca la referencia y escribe el Alias, da clic en Guardar",
                  3: "Ingresa nuevamente a Grupo de Servicios Varios y selecciona  el pago PayCash que creaste en el paso anterior",
                  4: "En Datos, ingresa el monto a pagar y da clic en Pagar",
                  5: "Confirma el pago dando clic en Realizar transferencia",
                },
              },
              AKISI: {
                agent: {
                  1: "Ask the cashier to make a PayCash payment through AKISI (Pronet) – “Grupo de Servicios Varios”",
                  2: "Provide the reference and amount of the payment at the cashier",
                  3: "*Whole Amounts Only",
                },
                bank: {
                  1: "Go to the Akisi Smart ATM",
                  2: "Go to the option : “Servicios Varios - Paycash – Pago”",
                  3: "Enter the reference number and click on “Confirmar”",
                  4: "Confirm the amount to be paid and click “OK”",
                  5: "Validate the payment details and click on “Confirmar”",
                  6: "The money will be deposited through the bill receiver - Enter the indicated amount",
                  7: "Receive your receipt for the payment made",
                  8: "Receive the change if you have deposited an amount greater than that requested",
                  9: "*Whole Amounts Only",
                },
                "bank-mobile": {
                  1: "Use your username and password in the AKISI app",
                  2: "Enter to the option:  “Servicios - Grupo de Servicios Varios – Paycash”",
                  3: "Enter the Reference and write the Alias (for example, “Pago PayCash”) - click on “Guardar”",
                  4: "Enter to the “Grupo de Servicios Varios” option again and select the “Pago Paycash” saved in the previous step",
                  5: "In the “Datos” section, enter the amount to be paid and click on “Pagar”",
                  6: "Confirm the payment by clicking “Realizar transferencia”",
                  7: "*Whole Amounts Only",
                },
              },
              "Super 24": {
                agent: {
                  1: "Ask the cashier to make a PayCash payment through AKISI (Pronet) –“Grupo de Servicios Varios”",
                  2: "Indicate the reference and amount of the payment at the cashier",
                  3: "*Whole Amounts Only",
                },
              },
            },
            PER: {
              "Western Union": {
                agent: {
                  1: "Ask the cashier to make a PayCash payment at one of these authorized agents: Caja Trujillo, Caja Los Andes (Crac – Lasa), Financiera QAPAQ, Edpyme Alternativa, Caja Paita, Caja Municipal Del Santa y Puntos Físico WU",
                  2: "Provide the <strong>16-digit</strong> reference (<strong>{{key}}</strong>) and payment amount to the cashier.",
                },
                "bank-web": {
                  1: "Select the “operaciones” option",
                  2: "Choose the “pagos” option",
                  3: "Select the “Instituciones y Empresas” option",
                  4: "Search for the name PayCash",
                  5: "Enter the <strong>16 digits</strong> of the reference (<strong>{{key}}</strong>) and select search",
                  6: "Confirm the payment",
                  7: "Authorize the operation via token",
                  8: "The bank confirms the status of the operation",
                },
              },
              "Banco BCP": {
                agent: {
                  1: "Ask the cashier to make a PayCash payment or bring the company code <strong>24162</strong>",
                  2: "Share the  <strong>last 13 digits</strong> (<strong>{{key}}</strong>) of the reference",
                  3: "Provide the reference  and payment amount to the cashier.",
                  4: "*Only whole amount operations",
                },
                "bank-web": {
                  1: "Enter to the payments section",
                  2: "Search by name (PayCash)",
                  3: "Enter the <strong>last 13 digits</strong> (<strong>{{key}}</strong>) of the reference",
                  4: "Confirm the payment",
                  5: "Enter the corresponding token",
                  6: "Confirm the status of the operation",
                  7: "*Only whole amount operations",
                },
              },
              Yape: {
                "bank-mobile": {
                  1: "Enter the Services payment section and search for PayCash by name",
                  2: "Enter the <strong>last {{digit}} digits</strong> of the Payment Code (<strong>{{key}}</strong>) and confirm the payment",
                  3: "Wait for status confirmation of the operation",
                },
              },
              BBVA: {
                agent: {
                  1: "Go to your nearest BBVA Agent and mention to the cashier that you are going to make a PAYCASH payment with the code <strong>{{key}}</strong>",
                },
                "bank-web": {
                  1: "Enter to the services payment section",
                  2: "Search for PayCash",
                  3: "Enter the 16 digits of the reference (<strong>{{key}}</strong>)",
                  4: "Confirm the payment",
                  5: "Enter the client's token",
                  6: "The bank confirms the status of the operation",
                },
              },
              Izipay: {
                wallet: {
                  1: "Enter the Services payment section and search for PayCash Service",
                  2: "Enter the Payment Code <strong>{{key}}</strong> and confirm the payment amount",
                  3: "Wait for status confirmation of the operation",
                },
              },
              Interbank: {
                agent: {
                  1: "Ask the cashier to make a PayCash payment or provide the company code <strong>0319901</strong>",
                  2: "Bring the 16-digit reference (<strong>{{key}}</strong>) and payment amount to the cashier",
                  3: "Receive proof of payment",
                  4: "*Only whole amount operations",
                },
                "bank-web": {
                  1: "Enter – “Paga o Recarga”",
                  2: "Select – “Pago de servicios”",
                  3: "Search for “PayCash”",
                  4: "Enter the 16-digit reference (<strong>{{key}}</strong>) and payment amount.",
                  5: "The bank confirms the status of the operation",
                  6: "*Only whole amount operations",
                },
                "bank-mobile": {
                  1: "Enter to “Operaciones / Servicios”",
                  2: "Search “Paycash”",
                  3: "Enter the 16-digit reference (<strong>{{key}}</strong>) and the amount",
                  4: "The bank confirms the status of the operation",
                  5: "*Only whole amount operations",
                },
              },
              "Banco Pichincha": {
                "bank-web": {
                  1: 'Enter the option "Operaciones", choose the section "Pagos" and search for "PayCash" under "Instituciones y Empresas"',
                  2: 'Enter the Payment Code <strong>{{key}}</strong> and click "search"',
                  3: "Confirm the amount and finish the payment by authorizing the operation via Token",
                  4: "Wait and make sure you get the Payment Confirmation",
                },
              },
              Kasnet: {
                agent: {
                  1: "Ask the cashier to make a PayCash payment to convenio <strong>220114</strong>",
                  2: "Provide the <strong>16-digit</strong> reference (<strong>{{key}}</strong>) and payment amount to the cashier.",
                },
              },
              Megapunto: {
                agent: {
                  1: "Go to your nearest Megapunto payment point and mention to the cashier that you are going to make a PAYCASH payment through the App - Services - Paycash",
                  2: "Provide the code <strong>{{key}}</strong> and amount then complete the payment",
                },
              },
              Bancom: {
                "bank-web": {
                  1: "Enter – “Pago de servicios”",
                  2: "Select “Sociedades”",
                  3: "Search / “Empresa PayCash y Servicio PayCash”",
                  4: "Enter the 16-digit reference (<strong>{{key}}</strong>) and amount",
                  5: "The application confirms the status of the operation",
                },
                "bank-mobile": {
                  1: "Enter – “Pago de servicios”",
                  2: "Select “Sociedades”",
                  3: "Search / “Empresa PayCash y Servicio PayCash”",
                  4: "Enter the 16-digit reference (<strong>{{key}}</strong>) and amount",
                  5: "The application confirms the status of the operation",
                },
              },
              "Red Digital": {
                agent: {
                  1: "Ask the cashier to make a PayCash payment through the RedDigital Web or App (MovilWay) – “Pasarelas de Pago” – “Paycash”",
                  2: "Provide the 16-digit reference (<strong>{{key}}</strong>) and payment amount to the cashier.",
                  3: "Additionally, you can bring your cell phone number to receive a payment confirmation by SMS",
                  4: "The collection point will charge an additional 1 Sol for the use of the service (In-person)",
                  5: "Receive proof of payment",
                },
              },
              Niubiz: {
                agent: {
                  1: "Ask the cashier to make a PayCash payment in the “Recargas y Servicios” option",
                  2: "Provide the 16-digit reference (<strong>{{key}}</strong>) and payment amount to the cashier.",
                },
              },
              "Caja Arequipa": {
                bank: {
                  1: "Insert your Caja Arequipa card and Password",
                  2: "Select “Otras opciones” -> “Pagos” -> “Servicios” ->“PayCash”",
                  3: "Enter the PayCash Reference number (<strong>{{key}}</strong>) and confirm the amount",
                  4: "Receive proof of payment",
                  5: "*Only whole amount operations",
                },
                online: {
                  1: "Enter to your Home Banking or Caja Arequipa Mobile App",
                  2: "Go to the Home menu under the “Pagos” option",
                  3: "Select “Pago de Servicios e Instituciones” -> “Otros” -> “PayCash”",
                  4: "Enter the PayCash Reference number (<strong>{{key}}</strong>) and confirm the amount",
                  5: "You will be asked for a digital code and token",
                  6: "Receive a digital payment receipt",
                  7: "*Only whole amount operations",
                },
              },
              Banbif: {
                agent: {
                  1: "Ask the cashier at a FULL CARGA collection point to make a PayCash payment",
                  2: "Enter the last 14 digits (<strong>{{key}}</strong>) of the PayCash Reference at the checkout and confirm the amount to be paid",
                  3: "Receive proof of payment",
                },
                "bank-web": {
                  1: "Log in to your Banbif Digital Banking https://www.banbif.com.pe/Personas",
                },
                "bank-mobile": {
                  1: "Enter your Banbif Mobile Application",
                },
                online: {
                  2: "Go to the “Pagar” menu in the “Servicios” option",
                  3: "Search for the “PayCash” service",
                  4: "Enter the last 14 digits (<strong>{{key}}</strong>) of the PayCash Reference and confirm the amount",
                  5: "Select the invoice to pay and select the payment method",
                  6: "Confirm and approve with your token code",
                  7: "Receive digital payment receipt",
                  8: "*Only whole amount operations",
                },
              },
            },
            ECU: {
              "Jardin Azuayo": {
                "bank-web": {
                  1: "Select Services -> Collections -> Payment of basic services -> taxes and catalog",
                  2: "Select other collections -> PayCash",
                  3: "Enter reference and payment amount",
                  4: "Confirm payment",
                },
              },
              "Western Union": {
                agent: {
                  1: "*Western Union modules inside  MI COMISARIATO, TIA, HIPERMARKET",
                },
              },
              "Banco Internacional": {
                "bank-web": {
                  1: "Enter digital banking; https://www.bancointernacional.com.ec/",
                  2: "Select the option “Banca Online Personas”",
                  3: "Enter your username and password",
                  4: "Select “Resumen -> Posición Consolidada -> Pagar’”",
                  5: "In “Grupo de Servicios” section, select the “casas comerciales” option -> In “Empresa” section, select “Recaudación Pay Cash” -> In “Convenio” section, select “Pay Cash”",
                  6: "Insert the reference and click on “Consultar",
                  7: "Choose the type of payment, the account where the money will be taken for the payment and click on “Continuar”",
                  8: "Validate the data and click on “Confirmar” -> a confirmation number will be sent to the registered phone number.",
                  9: "Obtain proof of your payment",
                },
              },
              "Mi Negocio Efectivo": {
                agent: {
                  1: "Ask the cashier to make a PayCash payment through “Pago de Servicio -> Pagos en Línea”",
                },
              },
            },
            CRI: {
              "Banco Nacional": {
                agent: {
                  1: "Ask the cashier to make a PayCash payment",
                  2: 'The cashier will search for the service by name "PayCash" on the platform directly or search for it by the route: (Pagos -> cuotas y créditos -> Cuotas-Ahorro-Crédito -> PayCash)',
                  3: "Provide the reference (<strong>{{key}}</strong>) and amount of the payment at the cashier.",
                },
                "bank-web": {
                  1: "Log in to the digital bank and register your username and password",
                  2: "In the “Acceso” section, in the search option, enter the name of the service: PayCash",
                  3: "Enter the 16-digit reference (<strong>{{key}}</strong>)",
                  4: "Confirm payment and select the payment method (debit or credit)",
                  5: "The bank confirms the status of the operation",
                },
              },
              Payser: {
                agent: {
                  1: "Ask the cashier to make a PayCash payment in the “Otros Pagos de Servicios” option",
                  2: "Provide the reference (<strong>{{key}}</strong>) and amount of the payment at the cashier",
                },
              },
              "Tiendas Ekono": {
                agent: {
                  1: "Ask the cashier to make a PayCash payment",
                  2: 'The cashier will search for the service by name "PayCash" on the platform directly or search for it by the route: (Pagos -> cuotas y créditos -> Cuotas-Ahorro-Crédito -> PayCash)',
                  3: "Provide the reference (<strong>{{key}}</strong>) and amount of the payment at the cashier",
                },
              },
              "Banco Popular": {
                agent: {
                  1: "Ask the cashier to make a PayCash payment",
                  2: 'The cashier will search for the service by name "PayCash" on the platform directly or search for it by the route: (Pagos -> cuotas y créditos -> Cuotas-Ahorro-Crédito -> PayCash)',
                  3: "Provide the reference (<strong>{{key}}</strong>) and amount of the payment at the cashier",
                },
                "bank-web": {
                  1: "Log in to the digital bank and register your username and password",
                  2: "In the “Acceso” section, in the search option, enter the name of the service: PayCash",
                  3: "Enter the 16-digit reference (<strong>{{key}}</strong>)",
                  4: "Confirm payment and select the payment method (debit or credit)",
                  5: "The bank confirms the status of the operation",
                },
              },
              "Banco Promerica": {
                "bank-web": {
                  1: "Log in to the digital bank and register your username and password",
                  2: "In the “Pago de Servicios” section, in the search option, enter the name of the service: PayCash",
                  3: "Enter the 16-digit reference (<strong>{{key}}</strong>)",
                  4: "Confirm payment and select the payment method (only debit)",
                  5: "The bank confirms the status of the operation",
                },
              },
            },
            MEX: {
              BWS: {
                agent: {
                  1: "Ask the cashier to make a PayCash payment to service <strong>198</strong>",
                },
              },
              Telecom: {
                agent: {
                  1: "Dirígete a tu punto de pago de efectivo Telecomm más cercano y menciónale al cajero que vas hacer un pago PAYCASH al Convenio <strong>{{code}}</strong> con el código <strong>{{key}}</strong>",
                },
              },
              Santander: {
                agent: {
                  1: "Request a PayCash payment to Convenio <strong>7292</strong> at the counter",
                },
                "bank-web": {
                  1: "Enter to online banking",
                  2: "Cuentas – operaciones más usadas",
                  3: "Servicios – PayCash",
                  4: "Capture “REFERENCIA” and “Importe”",
                },
              },
              BBVA: {
                bank: {
                  1: "Go to your nearest Practicaja",
                  2: "Select “pago de servicio”",
                  3: "Convenio <strong>1420712</strong>",
                  4: "Enter Reference and Amount",
                  5: "*Time in which the operation is reflected: 30 minutes to 1 hour",
                },
                "bank-web": {
                  1: "Saldo",
                  2: "Pagar Servicio",
                  3: "Nuevo Servicio",
                  4: "Enter “CIE1420712” and select “Red Efectiva”",
                  5: "Capture “REFERENCIA” and “Importe”",
                  6: "*Time in which the operation is reflected: 30 minutes to 1 hour",
                },
              },
              Banorte: {
                bank: {
                  1: "Request a PayCash payment at the counter to Convenio <strong>3724</strong>",
                  2: "Provide reference and the amount to be paid",
                  3: "*Time in which the operation is reflected: 24 to 72 business hours",
                },
                "bank-web": {
                  1: "Enter to “Pagos de servicios”",
                  2: "Search for Convenio <strong>3724</strong> or by name PAYCASH - TELECOMM",
                  3: "Select the account where the balance will be obtained",
                  4: "Capture “REFERENCIA” and “Importe”",
                  5: "*Time in which the operation is reflected: 24 to 72 business hours",
                },
              },
              "Banco AFIRME": {
                "bank-web": {
                  1: "Enter to “Pagos de servicios”",
                  2: "Search by name PAYCASH",
                  3: "Select the account where the balance will be obtained",
                  4: "Capture “REFERENCIA” and “Importe”",
                },
              },
            },
            PAN: {
              "Punto Pago": {
                agent: {
                  1: "At Kiosko, select the “PayCash” button",
                  2: "Enter the PayCash reference number and click on “Confirmar”",
                  3: "Deposit the requested amount and select “Pagar”",
                  4: "The system will print the payment receipt",
                  5: "*Please note that the Kiosko does not return change, you must deposit only the exact amount to be paid",
                },
                "bank-mobile": {
                  1: "Enter to the “Punto Pago” APP",
                  2: "Search for the PayCash operator",
                  3: "Enter the reference to be paid and select “Guardar”",
                  4: "Select “cuenta a pagar”, which was saved in the previous point",
                  5: "Enter the amount to deposit and select “Pagar”",
                },
              },
            },
          },
        },
      },
      pse: {
        company_info: "Company Information",
        business_name: "Business Name",
        trx_detail: "Transaction Details",
        trx_status: "Transaction Status",
        trx_date: "Transaction creation date",
        bank: "Bank",
        tracking_code: "Unique tracking code",
        reference: "Payment Reference (ticketID)",
        amount: "Payment Amount",
        description: "Payment Description",
        status: {
          FALLIDA: "FAILED",
          APROBADA: "APPROVED",
          RECHAZADA: "REJECTED",
          PENDIENTE: "PENDING",
        },
        alert: {
          pending:
            "Please, verify if the debit was processed by the bank. If you require more information about your transaction, please contact our customer support email at support.latam@pagsmile.com",
        },
        error: {
          FAIL_EXCEEDEDLIMIT:
            "The transaction amount exceeds the limits set in PSE for the company. Please contact our customer support email at support.latam@pagsmile.com for assistance",
          default:
            "The transaction cannot be created. Please try again later or contact our customer support email at support.latam@pagsmile.com",
        },
      },
    },
    search: "Search",
    select_bank: "Select your bank",
    fail_change_method: "Something went wrong. Please use another payment method.",
    bank_code: "Bank code:",
    no_channel: "No channel, please contact merchant",
    mon_bankMobile: "Banca Móvil",
    mon_bankWeb: "Banca Web",
    mon_bank: "Ventanilla",
    mon_agent: "Agentes",
    service_name: "Service",
    mon_chooseBank: "1. Escoge el banco o agente",
    mon_chooseMethod: "2. Escoge el método de pago",
    faq: "Frequently Asked Questions",
    pago_method: "Mobile banking, QR (Yape, Plin), Agents and Warehouses",
    efecty_faq1q: "What is Efecty?",
    efecty_faq1a:
      "Efecty is a company based in Colombia that offers a variety of payment methods including money orders, payments, recharges and collections nationwide. Customers can pay online and pay later at any of the 8000 service points in the country.",
    efecty_faq2q: "How can I find a place to pay in cash?",
    efecty_faq2a: "Go to https://mapapap.efecty.com.co/pagos-personas and search your address",
    efecty_faq3q: "How to know if my order was succesful?",
    efecty_faq3a:
      "The purchases are approved immediately and you will receive an email confirming your payment. If you have doubts with the purchase you are making, call us at the customer service lines.",
    khipu_faq1q: "What is Khipu?",
    khipu_faq1a:
      "Khipu is a way to receive payments with transfer that works on any device, responsively, and any browser.",
    khipu_faq2q: "How does Kihpu work?",
    khipu_faq2a:
      "Simply input your Khipu information (card number and password) and complete the transaction on your bank's website or app.",
    khipu_faq3q: "How to know if my order was succesful?",
    khipu_faq3a:
      "The purchases are approved immediately and you will receive an email confirming your payment. If you have doubts with the purchase you are making, call us at the customer service lines.",
    help_chat: "Help chat",
    okay: "Okay!",
    select_notice: "Select a payment method/channel",
    instructions: "Instructions",
    videos: "Videos",
    pictures: "Pictures",
    text: "Text",
    online_inst1: "Log on to online banking as usual.",
    online_inst2: "Enter your 6 digit password.",
    online_inst3: "Select Payment and Services and companies category. Then enter in the search “SafetyPay”.",
    online_inst4:
      "Selecting this option will request the transaction number and the amount that was provided by the merchant.",
    online_inst5: "Confirm the transaction by entering your electronic key (token) and you are done!",
    payment_method: "Payment Method",
    online_banking: "Online Banking",
    cash_payments: "Cash Payments",
    bank_agent: "Choose Bank/Agent",
    filling_details: "Filling Details",
    fill_info: "Fill your info",
    details: "Details",
    name: "Name",
    email: "Email",
    TIN: "TIN (Tax ID):",
    phone: "Mobile Phone Number",
    time_remain: "Time remaining:",
    trx_id: "Transaction ID:",
    hide: "Hide",
    show: "Show",
    product_description: "Product Description:",
    order_value: "Order Value:",
    bank_fee: "Bank fee",
    discount: "Discount",
    total: "Total",
    confirm: "Confirm",
    return: "Return",
    privacy_policy_error: "You must read and accept the Terms of Use and Privacy Policy to complete your purchase.",
    privacy_policy1: "I have read and agree to the terms of use and",
    privacy_policy2: " Privacy Policy",
    privacy_policy3: " of Luxpag",
    invalid_name: "Invalid name",
    invalid_TIN: "Invalid ID",
    invalid_email: "Invalid email",
    invalid_phone: "Invalid phone number",
    payment_inst_top:
      "Your order has been registered. We have registered your order and waiting for payment to start the delivery procedure. We're almost done!",
    payment_inst: "Payment Instructions:",
    payment_ticket: "Payment Ticket:",
    payment_code: "Payment Code:",
    attention: "ATTENTION: Please check the updated information below.",
    cash: "Cash",
    bncr_cash1:
      "Go to the nearest BN Servicios point and ask the cashier to make a {{key}} payment with the reference number provided above",
    bncr_cash2: "Pay and receive your voucher",
    bncr_bank1: "Enter the Internet Banking of Banco Nacional with your identification and password",
    bncr_bank2: "Perform search by name: Payválida and enter the reference number",
    bncr_bank3: "Reference will be confirmed, select the payment method and wait for confirmation",
    bncr_paycash0: "Go to the nearest BN Servicios point and ask the cashier to make a PayCash payment with the code ",
    bncr_paycash1: "Enter the Internet Banking of Banco Nacional with your identification and password",
    bncr_paycash2: "Perform search by name: Paycash and enter a 16-digit reference",
    bncr_paycash3: "Payment is confirmed, select the payment method and wait for confirmation",
    pay: "Pay:",
    pay2: "Pay",
    processing: "Processing",
    processing_notice: "The transaction is in process, it's going to be quick. Thank you for using Luxpag!",
    success: "Payment successful",
    success_notice: "You have paid the order successfully. Please return to seller page! Thank you for using Luxpag.",
    cancelled: "Cancelled",
    cancelled_notice:
      "The time limit for your payment has ended, please return back to seller page to make a new order.",
    failed: "Failed",
    copied: "Copied Successfully",
    copy: "Copy",
    visit_us: "To know more about us, visit",
    fail_notice: "It is possible that an information is wrong, try again or reach us",
    fail_query: "Do you want to try again?",
    credit_debit_card: "Credit/Debit Card",
    credit_card: "Credit Card",
    debit_card: "Debit Card",
    "payment.redirect.notice1": "You will be redirected to the merchant's page in {{key}} seconds.",
    "payment.redirect.notice2": "Click ",
    "payment.redirect.notice3": "here",
    "payment.redirect.notice4": " to cancel.",
    oxxo: "OXXO Cash Payment",
    wallet: "Wallets",
    yes: "Yes, I do",
    help_me: "Help me",
    order: "Order",
    back_shop: "Back to shopping",
    cardholder_name: "Cardholder Name",
    card_no: "Card Number",
    invalid_card_no: "Invalid card no",
    exp_year: "Exp. Year",
    exp_date: "Expiration Date (MM/YYYY)",
    invalid_exp_date: "Invalid expiration date",
    invalid_year: "Select a year",
    exp_month: "Month",
    month: "month",
    invalid_month: "Select a month ",
    state: "State",
    invalid_state: "Select a state",
    city: "City",
    invalid_city: "Select a city",
    postal_code: "Postal Code",
    invalid_postal_code: "Invalid postal code",
    street: "Street",
    invalid_street: "Invalid street",
    number: "No",
    invalid_number: "Invalid number",
    source: "Website or name of the APP where you purchased the product",
    invalid_source: "Invalid",
    source_notice: "In case of incorrect filling may be failure to pay the order",
    todito_notice: "Input your Todito Cash information below:",
    "checkout_inst1.1": "Dear user, pay ",
    "checkout_inst1.2": " to finish your purchase.",
    "checkout_inst2.1": "Scan the QR code to go to ",
    "checkout_inst2.1.1": "Click the button to go to ",
    "checkout_inst2.2": " App and complete the payment.",
    go_to: "Go to ",
    checkout_inst3: "1. Go to the closest store",
    checkout_inst4: "2. Tell the cashier you have a Luxpag payment and show this number:",
    share: "Share",
    save_pdf: "Save PDF",
    checkout_inst5: "Open your bank app and input the info below",
    checkout_inst6: "Input this reference number: ",
    register_info: "Info to register account:",
    installments: "Installments",
    invalid_cvv: "Invalid CVV",
    invalid_PIN: "Invalid PIN",
    invalid_NIP: "Invalid NIP",
    paid_major: "Major part paid",
    paid_minor: "Minor part paid",
    refund_verifying: "Verifying refund",
    refund_processing: "Processing refund",
    risk_control: "Controlling risks",
    refunded: "Refunded",
    partial_refund: "Partial refunded",
    chargeback: "Chargeback",
    chargeback_reversed: "Chargeback Reversed",
    dispute: "Dispute",
    dispute_reversed: "Dispute reversed",
    timeout: "Transaction timed Out",
    payment_refuse: "Payment refused",
    payment_fail: "Payment failed",
    refund_reverse: "Refund reversed",
    refund_refuse: "Refund refused",
    pay_close: "Closed",
    fail_sec: "For the security of your transaction, this order is closed. Please submit it again.",
    invalid_cep: "Invalid CEP",
    boleto_inst1: "Boleto generated!",
    boleto_inst2: "You can pay in banks, lotéricas, Correios, supermarkets or online.",
    boleto_inst3:
      "We’ve already sent you an email with the details and you can also save a file now by clicking on the ‘Save PDF’ button below.",
    deposit_upload_inst1: "Nice to have you back!",
    deposit_upload_inst2:
      "We’re almost there, just fill in this short form and upload the proof of payment to finish :)",
    upload: "Upload",
    back: "Back",
    wallet_inst1: "Scan and pay",
    wallet_inst2: "You can scan and pay using:",
    wallet_inst3: "How to pay?",
    wallet_inst4: "1. Open your wallet app on your device",
    wallet_inst5: "2. Tab on the “scan” feature",
    wallet_inst6: "3. Scan payment QR and complete the payment",
    wallet_mob_ins1: "Click and pay",
    wallet_mob_ins2: "You can click and pay using:",
    wallet_mob_ins3: "1. Click below button to go to ",
    "wallet_mob_ins3.1": " APP on your phone",
    wallet_mob_ins4: "2. Proceed to complete the payment",
    "SYS-0001": "Your payment has been declined. Choose another payment method. We recommend payment methods in cash.",
    "SYS-0002": "Time out",
    "SYS-0003": "Invalid transaction amount",
    "SYS-0004": "Payer must provide a valid email",
    "CC-0001": "Invalid operators users involved",
    "CC-0002": "Invalid card token",
    "CC-0003": "Pending contingency",
    "CC-0004": "Pending review manual",
    "CC-0005": "Rejected, bad filled card number",
    "CC-0006": "Rejected, bad filled date",
    "CC-0007": "Rejected, bad filled other",
    "CC-0008": "Rejected, bad filled security code",
    "CC-0009": "Rejected, in blacklist",
    "CC-0010": "Rejected, call for authorize",
    "CC-0011": "Rejected, card disabled",
    "CC-0012": "Rejected, card error",
    "CC-0013": "Rejected, duplicated payment",
    "CC-0014": "Rejected, high risk",
    "CC-0015": "Rejected, insufficient amount",
    "CC-0016": "Rejected, invalid installments",
    "CC-0017": "Rejected, max attempts",
    "CC-0018": "Rejected, other reason",
    bank_deposit: "Bank deposit",
    payment_lottery: "Payment in Lotérica",
    checkoutLottery_title: "This means of payment is not via bank transfer. Pay at the lottery using the data below:",
    barcode: "barcode",
    checkoutLottery_contentText1: "Health insurance code",
    checkoutLottery_contentText2: "CPF/CNPJ number",
    checkoutLottery_instContainer1: "Doubt when making payment？Click",
    checkoutLottery_instContainer2: "here",
    checkoutLottery_instContainer3: "and watch the explanatory video.",
    checkoutLottery_instContainer4: "Approval takes place within 1 hour of payment",

    depositExpress_inst_title1: "Payment is made in two steps:",
    depositExpress_inst_title2: "1. Make a transfer, deposit or PIX",
    depositExpress_inst_title3: "2. Submit proof of payment",
    depositExpress_bank_name: "Bank name",
    depositExpress_provider_agency: "Agency:",
    depositExpress_provider_number: "Checking account:",
    depositExpress_provider_owner: "Holder:",
    depositExpress_provider_owner_document: "CNPJ:",
    depositExpress_provider_chave_pix: "PIX key:",
    depositExpress_buttonContainer: "Click here to send proof",
    payment_type_value: "Type of payment",
    payment_type_value_invalid: "Invalid payment type",
    agent_value_helpertext: "Correct: 4-bit to 6-bit length, There may be '-' in the middle",
    agent_value_label: "Agency",
    account_valid_helpertext:
      "The correct format is 5 to 12 digits, there should be a '-' in the middle, and the last digit is a number or x(X).",
    account_valid_label: "Account",
    name_valid_label: "Full name",
    drop_zone_custom_title: "Upload proof of payment",
    file_valid_text: "Please upload a file",
    depositExpress_uploadSuccess: "File uploaded successfully. Wait for your payment to be processed and confirmed.",

    checkoutPix_instContainer:
      "Please open your payment app and scan the QR code below to pay or copy the Pix code below and paste into your payment app to finalize the purchase.",

    checkoutSpei_openpay_h4: "From BBVA",
    checkoutSpei_openpay_content1:
      '1. Within the "Pay" menu, select the option "Services" and enter the following "CIE Agreement Number"',
    checkoutSpei_openpay_instValue: "CIE agreement number:",
    checkoutSpei_openpay_content2: "2. Enter the registration data to complete the operation.",
    checkoutSpei_reference: "Reference:",
    checkoutSpei_openpay_monto: "Number:",
    checkoutSpei_openpay_from: "From any other bank",
    checkoutSpei_openpay_content3:
      "1. Enter the section of transfers and payments or payments to other banks and provide the transfer data:",
    checkoutSpei_openpay_beneficiary: "Beneficiary:",
    checkoutSpei_destinationbank: "Destination bank:",
    checkoutSpei_description: "Description:",
    checkoutSpei_amount: "Amount:",
    timed_out: "Transaction request timed out!",
    network_err: "Network error, please try again.",
    system_busy: "System busy, please try again later.",
    pay_points1: "You can click",
    pay_points2: " here ",
    pay_points3: "to see all payment points.",
    clap_cash_title: "How to pay?",
    pay_cash_ins1: "Go to your nearest PAYCASH payment point and request to pay with the code ",
    clap_cash_ins1: "Go to your nearest Klap EFECTIVO point and request to pay with the code ",
    clap_cash_ins2: "Tell the person at the merchant that the options on the machine are 2-8-1",
    clap_cash_ins3: "Pay and receive your receipt",
    pay_points_search: " to search nearest payment point.",
    klap_store1: "Use",
    klap_store2: " this link ",
    klap_store3: "to search for your nearest Klap store",
    address_billing: "Billing address:",
    address_delivery: "Delivery address:",
    address_same: "Same as billing address",
    select_country: "Please select a country first!",
    country: "Country",
    invalid_country: "Select a country",
    baloto_notice1: "Receipt VIA Baloto generated!",
    sured_notice1: "Receipt Su Red generated!",
    gana_notice1: "Receipt Gana generated!",
    baloto_notice2: "To make your payment, provide the following information at the VIA Baloto point of your choice.",
    sured_notice2: "To make your payment, provide the following information at the Su Red point of your choice.",
    gana_notice2: "To make your payment, provide the following information at the Gana point of your choice.",
    ref_no: "Reference no: ",
    agree_code: "Agreement No: ",
    id: "ID: ",
    amount: "Amount: ",
    due_date: "Due date",
    barcode_step1: "Go to the nearest payment point to pay with the pay code ",
    bank_list: "Bank List",
    bank_select: "Select a bank",
    agent: "Agents",
    agent_select: "Select an agent",
    account_number: "Account Number",
    account_invalid: "Invalid account number",
    checking_account: "Checking account",
    saving_account: "Savings account",
    master_account: "Master account",
    lp_ins1: "The Payment can be done by bank transfer or deposit.",
    lp_ins2: "Expiration date: ",
    lp_ins3: "Reference number: ",
    lp_ins4: "Account Information:",
    lp_ins5: "Bank name",
    lp_ins6: "Account type",
    lp_ins7: "Account number",
    lp_ins8: "Beneficiary name",
    lp_ins9: "Document type",
    lp_ins10: "Document ID",
    refresh: "Refresh",
    refresh_notice:
      "The page will be refreshed automatically in 30 seconds, otherwise please click on the button below.",
    lp_ins11: "The reference number must be exactly the same.",
    trsf1: "Open your financial institutions app",
    trsf2: "Go to: Services > Transactions > Transfiya",
    trsf3: "Select the account you want to pay with",
    trsf4: "Pay to this account: ",
    trsf5: "Remember to transfer the exact amount of ",
    trsf6: ", otherwise the transaction will fail",
    spei_key: "This key can only be used once",
    spei_clabe: "Interbank's CLABE",
    spei_bank: "Destination bank",
    spei_recipient: "Recipient",
    baloto_faq1q: "How to pay with VIA Baloto?",
    baloto_faq1a:
      "Tell the info to the cashier in the VIA Baloto point of your preference and check if everything is the same as in the receipt. This voucher is only valid for this order. If you make two payments, they will be cancelled.",
    payvalida_notice: "Receipt {{key}} generated!",
    payvalida_notice2: "To make your payment, provide the following information at the {{key}} point of your choice.",
    address: "Address",
    invalid_address: "Invalid address",
    digital_banking: "Digital banking",
    wallet_inst7: "Reference number can also be used to pay.",
    20000: "Service currently unavailable",
    40002: "Transaction failed",
    40013: "Order does not exist",
    40014: "Order has ended",
    40015: "Order information is inconsistent",
    40016: "Maximum amount (including fees) exceeded",
    40024: "Order timeout",
    20005: "Failed to upload",
    90001: "Bank is busy",
    "70001-10001": "The CPF entered is invalid, enter another one",
    "70001-10002": "This transaction cannot be processed. Contact Customer Support",
    "70001-10003": "This transaction cannot be processed. Contact Customer Support",
    "70001-10004": "This transaction cannot be processed. Contact Customer Support",
    "70001-20001": "Transaction limit exceeded",
    "70001-99999": "This transaction cannot be processed. Contact Customer Support",
    err_100001: "Something went wrong!, please try again later",
    err_100002: "Something went wrong! Please use another payment method.",
    err_100003: "Something went wrong! Please try to upload again",
    err_100004: "Upload failed!",
    err_10000: "Success",
    err_20000: "Service currently unavailable",
    err_20001: "Missing required parameters",
    err_20002: "Method not supported",
    err_20003: "Media type not supported",
    err_40000: "Transaction failed",
    err_40001: "Merchant number is invalid",
    err_40002: "Merchant is locked",
    err_40003: "Duplicate merchant order number",
    err_40004: "Integration interface is not supported",
    err_40005: "Direct interface is not supported",
    err_40010: "Invalid signature type",
    err_40011: "Invalid signature",
    err_40012: "Order does not exist",
    err_40013: "Order has ended",
    err_40014: "Order information is inconsistent",
    err_40015: "Maximum amount (including fees) exceeded",
    err_40016: "Currency is not supported",
    err_40017: "Unable to get the exchange rate",
    err_40020: "App is invalid",
    err_40021: "APP does not exist",
    err_40022: "App Key is invalid",
    err_40023: "App ID is inconsistent",
    err_40101: "Order timeout",
    err_40201: "Failed to upload",
    err_40301: "Bank is busy",
  },
};

export default en;
